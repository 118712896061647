<template>
  <div class="grupo">
    <!--<p>{{ actualizacion }}</p>-->
    <div class="table-responsive">
      <summary>GRUPO {{ nombreGrupo }}</summary>
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th colspan="2">País</th>
            <th>PJ</th>
            <th>PG</th>
            <th>PE</th>
            <th>PP</th>
            <th>GF</th>
            <th>GC</th>
            <th>Ptos</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="equipo in clasificacion" :key="equipo.equipo.id" v-bind:class="{enjuego: equipo.enjuego}">
            <td>
              <img :src="require('@/assets/img/flags/24/'+equipo['equipo']['_iso']+'.png')" />
            </td>
            <td>{{ equipo["equipo"]["abreviado"] }}</td>
            <td>{{ equipo["_pj"] }}</td>
            <td>{{ equipo["_pg"] }}</td>
            <td>{{ equipo["_pe"] }}</td>
            <td>{{ equipo["_pp"] }}</td>
            <td>{{ equipo["_gf"] }}</td>
            <td>{{ equipo["_gc"] }}</td>
            <td>{{ equipo["puntos"] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from 'vuex';

export default {
  name: "grupo",
  data() {
    return {
      actualizacion: 0,
      clasificacion: []
    };
  },
  props: ["nombreGrupo"],
  created() {
    this.datos_iniciales();
    this.$store.subscribe((mutation) => {
       if (mutation.type === this.nombreGrupo) {
         this.datos_iniciales();
       }
    });
  },
  methods: {
    actualizaTR: function(partido) {
      if (this.clasificacion.length > 0) {
        this.clasificacion.forEach(equipo => {
          if (equipo.equipo._iso == partido.resultado.local._iso) {
            equipo.enjuego = true;
            equipo._pj = equipo._pj + 1;
            if (parseInt(partido.resultado.local.goles) > 0) {
              equipo._gf = equipo._gf + parseInt(partido.resultado.local.goles);
            }
            if (parseInt(partido.resultado.visitante.goles) > 0) {
              equipo._gc = equipo._gc + parseInt(partido.resultado.visitante.goles);
            }
            if (parseInt(partido.resultado.local.goles) > parseInt(partido.resultado.visitante.goles)) {
              equipo._pg = equipo._pg + 1;
              equipo.puntos = equipo.puntos + 3;
            }
            else if (parseInt(partido.resultado.local.goles) < parseInt(partido.resultado.visitante.goles)) {
              equipo._pp = equipo._pp + 1;
            }
            else {
              equipo._pe = equipo._pe + 1 ;
              equipo.puntos = equipo.puntos + 1;
            }
          }
          else if (equipo.equipo._iso == partido.resultado.visitante._iso) {
            equipo.enjuego = true;
            equipo._pj = equipo._pj + 1;
            if (parseInt(partido.resultado.visitante.goles) > 0) {
              equipo._gf = equipo._gf + parseInt(partido.resultado.visitante.goles);
            }
            if (parseInt(partido.resultado.local.goles) > 0) {
              equipo._gc = equipo._gc + parseInt(partido.resultado.local.goles);
            }
            if (parseInt(partido.resultado.visitante.goles) > parseInt(partido.resultado.local.goles)) {
              equipo._pg = equipo._pg + 1;
              equipo.puntos = equipo.puntos + 3;
            }
            else if (parseInt(partido.resultado.visitante.goles) < parseInt(partido.resultado.local.goles)) {
              equipo._pp = equipo._pp + 1;
            }
            else {
              equipo._pe = equipo._pe +1 ;
              equipo.puntos = equipo.puntos + 1;
            }
          }
        })
        this.ordenaClasificacion()
      }
    },
    datos_iniciales: function() {
      let _this = this;
      let url = this.$store.state.ApiURL + "api/clasificacion/grupo/"+_this.nombreGrupo;
      axios.get(url)
        .then(response => {
          let data = response.data;
          _this.clasificacion = data.data;
          _this.clasificacion.forEach(equipo => {
            equipo.enjuego = false;
          })
          _this.ordenaClasificacion();
          if (this.$store.state.partidosEnJuego.length > 0) {
            this.$store.state.partidosEnJuego.forEach(partido => {
              if (partido.grupo == _this.nombreGrupo) {
                if (partido.resultado.tiempo!="Sin comenzar") {
                _this.actualizaTR(partido);
                }
              }
            });
          }
        })
        .catch(e => {
          alert(e);
      });
    },
    ordenaClasificacion: function() {
      this.clasificacion.sort(function(a, b) {
        if (a.posicion === b.posicion) {
          if (a.puntos === b.puntos) {
            if ((a._gf - a._gc) === (b._gf - b._gc)) {
              return a.equipo.abreviado > b.equipo.abrevido ? -1 : 1;
            } else if ((a._gf - a._gc) > (b._gf - b._gc)) {
              return -1;
            } else if ((a._gf - a._gc) < (b._gf - b._gc)) {
              return 1;
            }
          } else if (a.puntos > b.puntos) {
            return -1;
          } else if (a.puntos < b.puntos) {
            return 1;
          }
        } else if (a.posicion > b.posicion) {
          return 1;
        } else if (a.posicion < b.posicion) {
          return -1;
        }
      });
    }
  },
  computed: {
    ...mapState(['actualizaciones'])
  }
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
}
summary {
  margin-top: 5px;
  background-image: linear-gradient(to right, #000000, #dd0000, #fcee00);
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  cursor: default;
  font-weight: bold;
}
.enjuego {color: yellow;}
.table-responsive {margin-bottom: 2px;}
</style>