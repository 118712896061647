<template>
  <div>
    <table class="table table-sm">
      <tbody>
        <tr class="rojo">
          <td>
            <img
              :src="
                require('@/assets/img/flags/24/' +
                  partido['local']['_iso'] +
                  '.png')
              "
            />
            {{ partido["local"]["nombre"] }}
          </td>
          <td>{{ goles_local }}</td>
          <td>-</td>
          <td>{{ goles_visitante }}</td>
          <td>
            <img
              :src="
                require('@/assets/img/flags/24/' +
                  partido['visitante']['_iso'] +
                  '.png')
              "
            />
            {{ partido["visitante"]["nombre"] }}
          </td>
        </tr>
        <tr class="rojo">
          <td colspan="5">{{ tiempo }}</td>
        </tr>
        <tr v-for="apuesta in apuestas" :key="apuesta.id">
          <td colspan="4" :class="apuesta['clase']">
            {{ apuesta["usuario"] }}
          </td>
          <td :class="apuesta['clase']">{{ apuesta["apuesta"] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import EventBus from "@/eventBus";

export default {
  name: "partidoJuego",
  data() {
    return {
      actualizado: 0,
      apuestas: [],
      fetching: false,
      goles_local: 0,
      goles_visitante: 0,
      tiempo: "Sin comenzar",
      apuestaCorrecta: "X",
      id: 0,
      es: "",
      exportData: {}
    };
  },
  props: ["partido"],
  created() {
    this.exportData.local = this.partido.local;
    this.exportData.visitante = this.partido.visitante;
    this.exportData.tiempo = "Sin comenzar";
    this.$store.subscribe(mutation => {
      if (mutation.type === "TIEMPO" && this.actualizado == 0) {
        this.actualizado = 1;
        this.datos_iniciales();
      }
      if (mutation.type === "PARTIDOS") {
        this.es.close();
        this.$destroy();
      }
    });
  },
  methods: {
    datos_iniciales: function() {
      this.id = this.partido["id"];
      this.apuestas = this.partido.apuestas;
      this.sse(this.id);
      let partidoEnJuego = { id: this.id, apuestas: [], resultado: this.exportData, grupo: this.partido.grupo };
      this.$store.commit("PARTIDOSENJUEGO", partidoEnJuego);
      if (this.partido.grupo != "") {
        this.$store.commit(this.partido.grupo, Math.floor(Date.now() / 1000))
      }
      this.fetching = true;
    },
    sse: function(id) {
      const API_URL = process.env.VUE_APP_API_URL;
      this.es = new EventSource(API_URL + "api/resultado/" + id);
      let _this = this;
      _this.es.onmessage = function(e) {
        let data = JSON.parse(e.data);
        if (data.resultado.local == "-") data.resultado.local = "0";
        if (data.resultado.visitante == "-") data.resultado.visitante = "0";
        _this.goles_local = data.resultado.local;
        _this.goles_visitante = data.resultado.visitante;
        _this.tiempo = data.resultado.tiempo;
        _this.exportData.local.goles = _this.goles_local;
        _this.exportData.visitante.goles = _this.goles_visitante;
        _this.exportData.tiempo = _this.tiempo;
      };
    },
    cambioResultado: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.pintaApuestas();
        let partidoEnJuego = { id: this.id, apuestas: this.sumaClasificacion, resultado: this.exportData, grupo: this.partido.grupo };
        this.$store.commit("PARTIDOSENJUEGO", partidoEnJuego);
        if (this.partido.grupo != "") {
          this.$store.commit(this.partido.grupo, Math.floor(Date.now() / 1000))
        }
      }
    },
    cambioTiempoOResultado: function(newValue, oldValue) {
      let tiempoCorregido = this.tiempo.substr(0, 2);
      if (this.goles_local > this.goles_visitante && tiempoCorregido !== "Pr")
        this.apuestaCorrecta = "1";
      else if (
        this.goles_local < this.goles_visitante &&
        tiempoCorregido !== "Pr"
      )
        this.apuestaCorrecta = "2";
      else {
        if (this.goles_local > this.goles_visitante)
          this.apuestaCorrecta = "X(1)";
        else if (this.goles_local < this.goles_visitante)
          this.apuestaCorrecta = "X(2)";
        else this.apuestaCorrecta = "X";
      }
      this.cambioResultado(newValue, oldValue);
    },
    pintaApuestas: function() {
      if (this.tiempo !== "Sin comenzar") {
        for (var apuesta of this.apuestas) {
          if (apuesta["apuesta"] == this.apuestaCorrecta) {
            apuesta["clase"] = "acierto";
          } else if (
            this.id > 36 &&
            this.apuestaCorrecta.substr(0, 1) == "X" &&
            apuesta["apuesta"].substr(0, 1) == "X"
          ) {
            apuesta["clase"] = "semiacierto";
          } else {
            apuesta["clase"] = "";
          }
        }
      }
    }
  },
  filters: {
    fecha_legible: function(tiempo) {
      var date = new Date(tiempo * 1000);
      var hours = date.getHours();
      if (hours < 10) hours = "0" + hours;
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var month = date.getMonth();
      if (month < 10) month = "0" + month;
      var day = date.getDate();
      if (day < 10) day = "0" + day;
      var formattedTime =
        day +
        "/" +
        month +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2);
      return formattedTime;
    },
    restante: function(resta) {
      if (resta <= 0) {
        return "00:00:00";
      } else {
        let dias = Math.floor(resta / 86400);
        let horas = Math.floor((resta - 86400 * dias) / 3600);
        if (horas < 10) horas = "0" + horas;
        let minutos = Math.floor((resta - 86400 * dias - horas * 3600) / 60);
        if (minutos < 10) minutos = "0" + minutos;
        let segundos = resta - 86400 * dias - 3600 * horas - 60 * minutos;
        if (segundos < 10) segundos = "0" + segundos;
        if (dias > 0)
          return dias + "d" + " " + horas + ":" + minutos + ":" + segundos;
        else return horas + ":" + minutos + ":" + segundos;
      }
    }
  },
  watch: {
    goles_local: function(newValue, oldValue) {
      this.cambioTiempoOResultado(newValue, oldValue);
    },
    goles_visitante: function(newValue, oldValue) {
      this.cambioTiempoOResultado(newValue, oldValue);
    },
    tiempo: function(newValue, oldValue) {
      this.cambioTiempoOResultado(newValue, oldValue);
      if (oldValue == "Sin comenzar") {
        let partidoEnJuego = { id: this.id, apuestas: this.sumaClasificacion, resultado: this.exportData, grupo: this.partido.grupo };
        this.$store.commit("PARTIDOSENJUEGO", partidoEnJuego);
        if (this.partido.grupo != "") {
          this.$store.commit(this.partido.grupo, Math.floor(Date.now() / 1000))
        }
      }
    }
  },
  computed: {
    ...mapState(["actualizaciones"]),
    sumaClasificacion: function() {
      let calculo = [];
      let _this = this;
      let participante = {};
      for (var apuesta of this.apuestas) {
        if (_this.tiempo == "Sin comenzar") {
          participante = {
            login: apuesta["usuario"],
            apuestas: 0,
            aciertos: 0,
            puntos: 0
          };
        } else {
          participante = {
            login: apuesta["usuario"],
            apuestas: 1,
            aciertos: 0,
            puntos: 0
          };
          if (apuesta["apuesta"] == this.apuestaCorrecta) {
            participante["aciertos"] = 1;
            participante["puntos"] = _this.partido.puntos;
            if (this.apuestaCorrecta.length > 1) {
              participante["puntos"] = participante["puntos"] + 1;
            }
          } else {
            if (
              apuesta["apuesta"].substring(0, 1) == "X" &&
              this.apuestaCorrecta.substring(0, 1) == "X"
            ) {
              participante["aciertos"] = 1;
              participante["puntos"] = _this.partido.puntos;
            }
          }
        }
        calculo.push(participante);
      }
      return calculo;
    }
  },
  mounted() {}
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
  outline-style: solid;
  outline-width: 2px;
}
summary {
  margin-top: 5px;
  background-color: #007985;
}
.azul {
  background-color: #007985;
  font-size: 1.2em;
}
.rojo {
  background-color: #8a1538;
  font-size: 1.2em;
}
.acierto {
  background-color: #007000;
}
.semiacierto {
  background-color: yellow;
  color: black;
}
</style>
