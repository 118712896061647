import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from '@/store/auth.module'

Vue.use(Vuex)

export const TIEMPO = 'TIEMPO';
export const PORRA = 'PORRA';
export const PARTIDOS = 'PARTIDOS';
export const USUARIOS = 'USUARIOS';
export const CAMBIOPARTIDOS = 'CAMBIOPARTIDOS';
export const PREMIOS = 'PREMIOS';
export const PARTIDOSENJUEGO = 'PARTIDOSENJUEGO';
export const NUMEROPARTIDOSJUEGO = 'NUMEROPARTIDOSJUEGO';
export const LIMPIAPARTIDOS = 'LIMPIAPARTIDOS';
export const LANZAPARTIDO = 'LANZAPARTIDO';
export const A = 'A';
export const B = 'B';
export const C = 'C';
export const D = 'D';
export const E = 'E';
export const F = 'F';

export default new Vuex.Store({
    state: {
        actualizaciones: { porra: 0, partidos: 0, tiempo: 0, usuarios: 0, A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0},
        cambioPartidos: false,
        premios: 0,
        ApiURL: process.env.VUE_APP_API_URL,
        partidosEnJuego: [],
        numeroPartidosEnJuego: 0,
        lanzaPartido: 0
    },
    mutations: {
        [NUMEROPARTIDOSJUEGO](state,valor) {
            state.numeroPartidosEnJuego = valor;
        },
        [LIMPIAPARTIDOS](state) {
            state.partidosEnJuego = [];
        },
        [PARTIDOSENJUEGO](state,valor) {
            let nuevosPartidos = [];
            nuevosPartidos.push(valor);
            for (var partido of state.partidosEnJuego) {
                if (partido.id != valor.id) {
                    nuevosPartidos.push(partido);
                }
            }
            state.partidosEnJuego = nuevosPartidos;
        },
        [PREMIOS](state,valor) {
            state.premios = valor;
        },
        [TIEMPO](state,valor) {
            state.actualizaciones.tiempo = valor;
        },
        [PORRA](state,valor) {
            state.actualizaciones.porra = valor;
        },
        [PARTIDOS](state,valor) {
            state.actualizaciones.partidos = valor;
        },
        [USUARIOS](state,valor) {
            state.actualizaciones.usuarios = valor;
        },
        [A](state,valor) {
            state.actualizaciones.A = valor;
        },
        [B](state,valor) {
            state.actualizaciones.B = valor;
        },
        [C](state,valor) {
            state.actualizaciones.C = valor;
        },
        [D](state,valor) {
            state.actualizaciones.D = valor;
        },
        [E](state,valor) {
            state.actualizaciones.E = valor;
        },
        [F](state,valor) {
            state.actualizaciones.F = valor;
        },
        [CAMBIOPARTIDOS](state,valor) {
            state.cambioPartidos = valor;
        },
        [LANZAPARTIDO](state) {
            state.lanzaPartido = 0;
        }
    },
    actions: {
        actualizaciones(context) {
            let es = new EventSource(context.state.ApiURL+"api/sse");
            es.onmessage = function (e) {
                let data = JSON.parse(e.data);
                context.commit(TIEMPO, data.actualizacion.tiempo);
                if (data.actualizacion.hasOwnProperty("usuarios")) {
                    context.commit(USUARIOS,data.actualizacion.usuarios);
                }
                if (data.actualizacion.hasOwnProperty("partidos")) {
                    context.commit(PARTIDOS,data.actualizacion.partidos);
                }
                if (data.actualizacion.hasOwnProperty("porra")) {
                    context.commit(PORRA,data.actualizacion.porra);
                }
                if (data.actualizacion.hasOwnProperty("A")) {
                    context.commit(A,data.actualizacion.A);
                }
                if (data.actualizacion.hasOwnProperty("B")) {
                    context.commit(B,data.actualizacion.B);
                }
                if (data.actualizacion.hasOwnProperty("C")) {
                    context.commit(C,data.actualizacion.C);
                }
                if (data.actualizacion.hasOwnProperty("D")) {
                    context.commit(D,data.actualizacion.D);
                }
                if (data.actualizacion.hasOwnProperty("E")) {
                    context.commit(E,data.actualizacion.E);
                }
                if (data.actualizacion.hasOwnProperty("F")) {
                    context.commit(F,data.actualizacion.F);
                }
            };
        }
    },
    modules: {
        auth
    }
})