<template>
<div class="row">
    <div class="col-md-6" v-if="fetching">
      <h2>Próxima apuesta</h2>
      <div v-if="proximaApuesta.length > 0">
        <table class="table table-sm">
          <tbody>
            <tr v-for="partido in proximaApuesta" :key="partido.id">
              <td>{{ partido["fecha"] | fecha_legible }}</td>
              <td class="izquierda">
                <img :src="require('@/assets/img/flags/24/'+partido['local']['_iso']+'.png')" />
                {{ partido['local']['nombre'] }}</td><td class="izquierda">
                <img
                  :src="require('@/assets/img/flags/24/'+partido['visitante']['_iso']+'.png')"
                />
                {{ partido['visitante']['nombre'] }}
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="muestraReloj">Cierre de apuestas en: {{ resta | restante }}</p>
      </div>
      <p v-else>No hay más partidos.</p>
    </div>
    <div class="col-md-6" v-if="fetching">
      <h2>Próximos partidos</h2>
      <table class="table table-sm" v-if="proximosPartidos.length > 0">
        <tbody>
          <tr v-for="partido in proximosPartidos" :key="partido.id">
            <td>{{ partido["fecha"] | fecha_legible }}</td>
            <td class="izquierda">
              <img :src="require('@/assets/img/flags/24/'+partido['local']['_iso']+'.png')" />
              {{ partido['local']['nombre'] }}</td><td class="izquierda">
              <img
                :src="require('@/assets/img/flags/24/'+partido['visitante']['_iso']+'.png')"
              />
              {{ partido['visitante']['nombre'] }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>No hay más partidos.</p>
    </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "proximosPartidos",
  data() {
    return {
      proximaApuesta: [],
      proximosPartidos: [],
      actualizado: 0,
      limite: 4,
      fetching: false,
      proximo: 2000000000,
      muestraReloj: false
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "TIEMPO" && this.actualizado == 0) {
        this.tiempo = this.$store.state.actualizaciones.tiempo;
        this.actualizado = 1;
        this.datos_iniciales();
      }
    });
    
  },
  methods: {
    datos_iniciales: function() {
      let _this = this;
      let url = this.$store.state.ApiURL + "api/partidos";
      this.proximo = 2000000000;
      axios
        .get(url)
        .then(response => {
          let data = response.data;
          let tiempoUltimo = 0;
          _this.proximaApuesta = [];
          _this.proximosPartidos = [];
          for (var partido of data.data) {
            if (_this.proximaApuesta.length == 0 && !partido.resultado) {
              _this.proximaApuesta.push(partido);
              tiempoUltimo = partido.fecha;
              _this.proximo = tiempoUltimo;
            } else {
              if (partido.fecha == tiempoUltimo) {
                _this.proximaApuesta.push(partido);
              } else {
                if (_this.proximosPartidos.length < _this.limite)
                  _this.proximosPartidos.push(partido);
              }
            }
          }
          this.muestraReloj = true;
          this.fetching = true;
        })
        .catch(e => {
          alert(e);
        });
    }
  },
  filters: {
    fecha_legible: function(tiempo) {
      var date = new Date(tiempo * 1000);
      var hours = date.getHours();
      if (hours < 10) hours = "0" + hours;
      var minutes = "0" + date.getMinutes();
      var month = date.getMonth()+1;
      if (month < 10) month = "0" + month;
      var day = date.getDate();
      if (day < 10) day = "0" + day;
      var formattedTime =
        day +
        "/" +
        month +
        " " +
        hours +
        ":" +
        minutes.substr(-2)
      return formattedTime;
    },
    restante: function(resta) {
      if (resta <= 0) {
        return "00:00:00";
      } else {
        let dias = Math.floor(resta / 86400);
        let horas = Math.floor((resta - 86400 * dias) / 3600);
        if (horas < 10) horas = "0" + horas;
        let minutos = Math.floor((resta - 86400 * dias - horas * 3600) / 60);
        if (minutos < 10) minutos = "0" + minutos;
        let segundos = resta - 86400 * dias - 3600 * horas - 60 * minutos;
        if (segundos < 10) segundos = "0" + segundos;
        if (dias > 0)
          return dias + "d" + " " + horas + ":" + minutos + ":" + segundos;
          else
          return horas + ":" + minutos + ":" + segundos;
      }
    }
  },
  computed: {
    ...mapState(["actualizaciones"]),
    resta: function() {
      let valor = this.proximo - 300 - this.$store.state.actualizaciones.tiempo;
      if (valor<=0)
        return 0;
      else
        return valor;
    }
  },
  watch: {
    resta(valor) {
      if (valor <= 0) {
        this.muestraReloj = false;
        this.datos_iniciales();
        this.$store.commit("LANZAPARTIDO");
      }
    }
  }
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
}
summary {
  margin-top: 5px;
  background-color: #007985;
}
.izquierda {text-align: left;}
</style>