<template>
  <div :class="claseClasificacion" v-if="fetching && clasificacion.length>0 && porraComenzada">
    <h2>Clasificación<small class="text-muted">{{ titulo }}</small></h2>
    <table class="table table-sm">
      <thead>
        <th>Pos</th>
        <th>Participante</th>
        <th>Ptos</th>
        <th>Apuestas</th>
        <th>Aciertos</th>
      </thead>
      <tbody>
        <tr v-for="participante in clasificacionTiempoReal" :key="participante.id">
          <td :class="participante['clase']">{{ participante["pos"] }}</td>
          <td :class="participante['clase']">{{ participante["login"] }}
          <img v-if="participante['sign'] == 1" :src="require('@/assets/img/tic.png')" style="width:16px;" />
          <img v-if="participante['sign'] == 2" :src="require('@/assets/img/tic.png')" style="width:16px;" />
          <img v-if="participante['sign'] == 2" :src="require('@/assets/img/tic.png')" style="width:16px;" />
          </td>
          <td :class="participante['clase']">{{ participante["puntos"] }}</td>
          <td :class="participante['clase']">{{ participante["apuestas"] }}</td>
          <td :class="participante['clase']">{{ participante["aciertos"] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
//import EventBus from "@/eventBus";

export default {
  name: "clasificacion",
  data() {
    return {
      clasificacion: [],
      fetching: false,
      actualizado: 0,
      TR: [],
      clasificacionTiempoReal: [],
      salida: [],
      porraComenzada: false
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "TIEMPO" && this.actualizado == 0) {
        this.actualizado = 1;
        this.datos_iniciales();
      }
      if (mutation.type === "PARTIDOSENJUEGO") {
        this.datos_iniciales();
      }
      if (mutation.type === "CAMBIOPARTIDOS") {
        this.datos_iniciales();
      }
    });
    
  },
  methods: {
    datos_iniciales: function() {
      let _this = this;
      let url = this.$store.state.ApiURL + "api/clasificacion";
      axios
        .get(url)
        .then(response => {
          let data = response.data;
          _this.clasificacion = [];
          let apuestas = 0;
          for (var participante of data.data) {
            if (participante.apuestas > apuestas)
              apuestas = participante.apuestas;
            _this.clasificacion.push(participante);
          }
          _this.clasificacionTiempoReal = _this.clasificacion;
          _this.coloreaClasificacion();
          if (apuestas > 0) {
            _this.porraComenzada = true;
          }
          this.actualizaClasificacionTR(_this.$store.state.partidosEnJuego);
          this.fetching = true;
        })
        .catch(e => {
          alert(e);
        });
    },
    actualizaClasificacionTR: function(partidosEnJuego) {
      this.clasificacionTiempoReal = [];
      let aux = [];
      let numapuestas = 0;
      for (var participante of this.clasificacion) {
        let participanteAux = {};
        participanteAux.aciertos = participante.aciertos;
        participanteAux.puntos = participante.puntos;
        participanteAux.apuestas = participante.apuestas;
        participanteAux.login = participante.login;
        participanteAux.clase = "";
        participanteAux.sign = 0;
        for (var partidoTR of partidosEnJuego) {
           /* eslint-disable no-console */
          console.log(partidoTR)
          /* eslint-enable no-console */
          if (partidoTR.apuestas !== null) {
            for (var apuestas of partidoTR.apuestas) {
              if (apuestas.login == participante.login) {
                participanteAux.sign = participanteAux.sign + apuestas.aciertos;
                participanteAux.aciertos =
                  participanteAux.aciertos + apuestas.aciertos;
                participanteAux.puntos =
                  participanteAux.puntos + apuestas.puntos;
                participanteAux.apuestas =
                  participanteAux.apuestas + apuestas.apuestas;
                if (participanteAux.apuestas > numapuestas) {
                  numapuestas = participanteAux.apuestas;
                }
              }
            }
          }
        }
        aux.push(participanteAux);
      }
      this.clasificacionTiempoReal = aux;
      this.ordenaClasificacion();
      this.coloreaClasificacion();

      if (numapuestas > 0) {
        this.porraComenzada = true;
      }
    },
    ordenaClasificacion: function() {
      this.clasificacionTiempoReal.sort(function(a, b) {
        if (a.puntos === b.puntos) {
          if (a.aciertos === b.aciertos) {
            if (a.apuestas === b.apuestas) {
              return a.login.toLowerCase() > b.login.toLowerCase() ? 1 : -1;
            } else if (a.apuestas > b.apuestas) {
              return -1;
            } else if (a.apuestas < b.apuestas) {
              return 1;
            }
          } else if (a.aciertos > b.aciertos) {
            return -1;
          } else if (a.aciertos < b.aciertos) {
            return 1;
          }
        } else if (a.puntos > b.puntos) {
          return -1;
        } else if (a.puntos < b.puntos) {
          return 1;
        }
      });
    },
    coloreaClasificacion: function() {
      let posicion_actual = 1;
      let lista_posiciones = [];
      let max_apuestas = 0;
      let contador = 1;
      let jugador_anterior = { puntos: 0, aciertos: 0, apuestas: 0 };
      let ultimo = "";

      for (var participante of this.clasificacionTiempoReal) {
        if (contador == 1) {
          participante.clase = posicion_actual;
          participante.pos = posicion_actual;
        } else {
          if (
            participante.puntos < jugador_anterior.puntos ||
            participante.aciertos < jugador_anterior.aciertos ||
            participante.apuestas < jugador_anterior.apuestas
          ) {
            posicion_actual = contador;
          }
          participante.clase = posicion_actual;
          participante.pos = posicion_actual;
        }
        if (participante.apuestas > max_apuestas) {
          max_apuestas = participante.apuestas;
        }
        jugador_anterior.puntos = participante.puntos;
        jugador_anterior.aciertos = participante.aciertos;
        jugador_anterior.apuestas = participante.apuestas;
        if (lista_posiciones.indexOf(posicion_actual) == -1) {
          lista_posiciones.push(posicion_actual);
        }
        contador++;
      }

      for (var posicion of lista_posiciones) {
        for (var jugador of this.clasificacionTiempoReal) {
          if (jugador.clase == posicion && jugador.apuestas == max_apuestas) {
            ultimo = posicion;
          }
        }
      }
      let num = this.clasificacionTiempoReal.length;
      for (var jugador2 of this.clasificacionTiempoReal) {
        if (jugador2.clase == 1) jugador2.clase = "oro";
        else if (jugador2.clase == 2 && num > 4) jugador2.clase = "plata";
        else if (jugador2.clase == 3 && num > 24) {
          jugador2.clase = "bronce";
        } else if (jugador2.clase == ultimo && num > 14) {
          jugador2.clase = "perdedor";
        }
      }
    }
  },
  mounted() {
    /*EventBus.$on("SUMA_CLASIFICACION", sumas => {
      this.clasificacionTR(sumas);
    });
    EventBus.$on("PREMIOS_CARGADOS", usuarios => {
      this.coloreaClasificacion(usuarios);
    });*/
  },
  computed: {
    ...mapState(["actualizaciones"]),
    claseClasificacion: function() {
      if (this.$store.state.numeroPartidosEnJuego == 1)
        return "col-xl-6";
      else
        return "col-xl-12";
    },
    titulo: function() {
      if (this.$store.state.numeroPartidosEnJuego > 0) {
        return " (Tiempo real)";
      }
      else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
  outline-style: solid;
  outline-width: 2px;
}
summary {
  margin-top: 5px;
  background-color: #007985;
}
.azul {
  background-color: #007985;
  font-size: 1.2em;
}
.oro {
  background-color: yellow;
  color: black;
}
.plata {
  background-color: #c0c0c0;
  color: black;
}
.bronce {
  background-color: #8c7853;
  color: black;
}
.perdedor {
  background-color: red;
  color: white;
}
</style>