<template>
  <div class="row">
    <div class="col-xl-12" v-if="fetching">
      <h2>Premios <small>Inscritos: {{ premios.usuarios }} </small></h2>
      <div class="table-responsive" v-if="premios.primero">
        <table class="table table-condensed premios">
          <tbody>
            <tr class="oro"><td>Ganador</td><td>{{ premios.primero }}€</td></tr>
            <tr class="plata" v-if="premios.segundo"><td>Segundo</td><td>{{ premios.segundo }}€</td></tr>
            <tr class="bronce" v-if="premios.tercero"><td>Tercero</td><td>{{ premios.tercero }}€</td></tr>
            <tr class="perdedor" v-if="premios.ultimo"><td>Último</td><td>{{ premios.ultimo }}€</td></tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EventBus from '@/eventBus';

export default {
  name: "premios",
  data() {
    return {
      premios: [],
      fetching: false,
    };
  },
  created() {
    this.datos_iniciales();
    this.$store.subscribe(mutation => {
      if (mutation.type === "USUARIOS") {
        this.datos_iniciales();
      }
    });
  },
  methods: {
    datos_iniciales: function() {
      let _this = this;
      let url = this.$store.state.ApiURL + "api/premios";
      axios
        .get(url)
        .then(response => {
          let data = response.data;
          _this.premios = data.data;
          _this.fetching = true;
          this.$store.commit("PREMIOS",_this.premios.usuarios);
          EventBus.$emit("PREMIOS_CARGADOS", _this.premios.usuarios);
        })
        .catch(e => {
          alert(e);
        });
    }
  },
  computed: {
    ...mapState(["actualizaciones"])
  }
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
}
.oro {background-color: yellow;color:black;font-weight:bold;font-size:1.2em;}
.plata {background-color: #C0C0C0;color: black;font-weight:bold;}
.bronce {background-color: #8C7853;color: black;font-weight:bold;}
.perdedor {background-color: red; color: white;font-weight:bold;}
small {font-size: 0.7em;}
.premios {font-size: 0.9em;border:1px solid white;}
h2 {
  border:1px solid white;
  background-image: linear-gradient(to right, #000000, #dd0000, #fcee00);
  margin-bottom:0;
}
</style>