import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/instrucciones',
    name: 'instrucciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Instrucciones.vue')
  },
  {
    path: '/password',
    name: 'password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Password.vue')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usuarios.vue')
  },
  {
    path: '/resultados',
    name: 'resultados',
    component: () => import(/* webpackChunkName: "about" */ '../views/Resultados.vue')
  },
  {
    path: '/partidos',
    name: 'partidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Partidos.vue')
  },
  {
    path: '/historico',
    name: 'historico',
    component: () => import(/* webpackChunkName: "about" */ '../views/Historico.vue')
  },
  {
    path: '/apuestas',
    name: 'apuestas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Apuestas.vue')
  },
  {
    path: '/inscripcion',
    name: 'inscripcion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Inscripcion.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
