<template>
  <div v-if="fetching && partidos.length>0" class="" :class="clasePartidos">
      <h2 v-if="partidos.length==2">En juego</h2>
      <span v-if="partidos.length==1">
      <div class="" v-for="partido in partidos" :key="partido.id">
        <h2>En juego</h2>
        <partidoJuego :partido=partido></partidoJuego>
      </div>  
      </span>
      <span v-if="partidos.length==2">
        <div class="row">
        <div class="col-xl-6" v-for="partido in partidos" :key="partido.id">
        <partidoJuego :partido=partido></partidoJuego>
      </div>  
      </div>
      </span>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import PartidoJuego from '@/components/PartidoJuego';

export default {
  name: "partidosJuego",
  components: {
    PartidoJuego
  },
  data() {
    return {
      partidos: [],
      fetching: false,
      actualizado: 0,
      partidosCargados: 0,
      proximo: 2000000000
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "TIEMPO" && this.actualizado == 0) {
        this.actualizado = 1;
        this.datos_iniciales();
      }
      if (mutation.type === "PARTIDOS") {
        this.fetching = false;
        this.$store.commit("LIMPIAPARTIDOS");
        this.datos_iniciales();
      }
      if (mutation.type === "LANZAPARTIDO") {
        this.fetching = false;
        this.$store.commit("LIMPIAPARTIDOS");
        this.datos_iniciales();
      }
    });
  },
  methods: {
    datos_iniciales: function() {
      this.partidosCargados = 0;
      let _this = this;
      let url =
        this.$store.state.ApiURL + "api/partidos/enjuego";
      axios
        .get(url)
        .then(response => {
          let data = response.data;
          _this.partidos = [];
          for (var partido of data.data) {
            _this.partidos.push(partido);
            if (partido.fecha <_this.proximo) {
              _this.proximo = partido.fecha;
            }
          }
          this.$store.commit("NUMEROPARTIDOSJUEGO", _this.partidos.length);
          this.$store.commit("CAMBIOPARTIDOS", true);
          this.fetching = true;
        })
        .catch(e => {
          alert(e);
        });
    }
  },
  computed: {
    ...mapState(["actualizaciones"]),
    clasePartidos: function() {
      if (this.partidos.length == 2) 
        return "col-xl-12";
      else
        return "col-xl-6";
    }
  },
  mounted() {
  },
  watch: {
    partidosCargados: function() {
    }
  }
};
</script>

<style scoped>
table {
  font-size: 0.8em;
  color: white;
  outline-style: solid;
  outline-width: 2px;
}
summary {
  margin-top: 5px;
  background-color: #007985;
}
.azul {
  background-color: #007985;
  font-size: 1.2em;
}
</style>