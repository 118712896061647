<template>
  <div id="app" v-bind:class="{cursor: cursor}">
    <div class="sticky-top container-fluid">
      <div class="row">
        <router-link to="/" class="nav-link banner"> <img id="banner" src="@/assets/img/bannerXL.png" alt="Banner" class="img-fluid" /></router-link>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-0 pl-0 pr-0">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarNavDropdown" class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-if="loggedIn && rol=='ROLE_PAGADO'">
            <router-link to="/apuestas" class="nav-link">Apuestas</router-link>
          </li>
          <li class="nav-item" v-if="loggedIn">
            <router-link to="/historico" class="nav-link">Histórico</router-link>
          </li>
          <li class="nav-item" v-if="!loggedIn">
            <router-link to="/password" class="nav-link">Recuperar contraseña</router-link>
          </li>
          <li class="nav-item" v-if="!loggedIn">
            <router-link to="/inscripcion" class="nav-link">Inscripción</router-link>
          </li>
          <li class="nav-item" v-if="(!loggedIn || (loggedIn && rol=='ROLE_PAGADO'))">
            <router-link to="/instrucciones" class="nav-link">Instrucciones</router-link>
          </li>
          <li class="nav-item" v-if="loggedIn && rol=='ROLE_ADMIN'">
            <router-link to="/partidos" class="nav-link">Partidos</router-link>
          </li>
          <li class="nav-item" v-if="loggedIn && rol=='ROLE_ADMIN'">
            <router-link to="/resultados" class="nav-link">Resultados</router-link>
          </li>
          <li class="nav-item" v-if="loggedIn && rol=='ROLE_ADMIN'">
            <router-link to="/usuarios" class="nav-link">Usuarios</router-link>
          </li>
          <button
            type="button"
            class="my-auto mb-2 mr-sm-2 btn btn-danger btn-sm"
            id="salir"
            @click="logout"
            v-if="loggedIn"
          >Salir</button>
        </ul>
        <form
          id="formulariologin"
          @submit.prevent="login"
          method="post"
          class="d-sm-flex ml-2 mb-2 mb-lg-0"
          v-if="!loggedIn"
        >
          <input
            type="text"
            name="usuario"
            id="usuario"
            v-model="user.username"
            required
            placeholder="Usuario"
            class="my-sm-auto mb-2 form-control mr-1 form-control-sm"
          />
          <input
            type="password"
            name="password"
            id="password"
            v-model="user.password"
            required
            placeholder="Password"
            class="my-sm-auto mb-2 mr-1 form-control form-control-sm"
          />
          <button
            type="submit"
            class="my-auto mb-2 mr-xs-2 btn btn-primary btn-sm"
            id="hacerlogin"
          >Entrar</button>
        </form>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-6 order-xl-4" v-if="this.$router.history.current.name !== 'historico'">
          <router-view></router-view>
        </div>
        <div class="col-xl-12" v-else>
          <router-view></router-view>
        </div>
        <div id="tablasclasificacionesizq" class="col-xl-3 order-xl-1" v-if="this.$router.history.current.name !== 'historico'">
          <grupo nombreGrupo="A"></grupo>
          <grupo nombreGrupo="B"></grupo>
          <grupo nombreGrupo="C"></grupo>
        </div>
        <div id="tablasclasificacionesder" class="col-xl-3 order-xl-12" v-if="this.$router.history.current.name !== 'historico'">
          <grupo nombreGrupo="D"></grupo>
          <grupo nombreGrupo="E"></grupo>
          <grupo nombreGrupo="F"></grupo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Grupo from "./components/Grupo";
import User from "./models/user";

export default {
  name: "App",
  components: {
    Grupo
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      cursor: false,
      chrome: this.$browserDetect.meta.name
    };
  },
  created() {
    this.actualiza();
  },
  mounted() {
  },
  methods: {
    actualiza: function() {
      this.$store.dispatch("actualizaciones");
    },
    login: function() {
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            if (this.$store.state.auth.user.roles.length > 0) {
              let rol = this.$store.state.auth.user.roles[0];
              if (rol === "ROLE_PAGADO") {
                this.$router.push("/apuestas");
              }
              else if (rol === "ROLE_ADMIN") {
                this.$router.push("/resultados");
              }
              else if (rol === "ROLE_NOPAGADO") {
                this.nopagado();
              }
            }
          },
          error => {
            alert("El nombre de usuario o el password es incorrecto");
            this.loading = false;
            this.message = error.message;
          }
        );
      }
    },
    logout: function() {
      this.cursor = true;
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
      this.cursor = false;
    },
    nopagado: function() {
      this.$store.dispatch('auth/logout');
      alert("El pago de la inscripción aún no ha sido registrado.");
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    rol() {
      return this.$store.state.auth.user.roles[0];
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
  min-height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,1);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: yellow;
}

.navbar {margin-left:15px; margin-right: 15px;}

.cursor {cursor:wait;}
.banner {padding-top: 0px;padding-bottom:0px;}
</style>
