import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    const params = new URLSearchParams();
    params.append('login', user.username);
    params.append('password',user.password);
    return axios
      .post(API_URL + 'api/login_check', params)
      .then(this.handleResponse)
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user-porra', JSON.stringify(response.data));
        }
        return response.data;
    });
  }

  logout() {
    localStorage.removeItem('user-porra');
  }

  handleResponse(response) {
    if (response.status === 401) {
      this.logout();
      location.reload(true);

      const error = response.data && response.data.message;
      return Promise.reject(error);
    }

    return Promise.resolve(response);
  }
}

export default new AuthService();