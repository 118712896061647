import { render, staticRenderFns } from "./Clasificacion.vue?vue&type=template&id=602ee2bd&scoped=true&"
import script from "./Clasificacion.vue?vue&type=script&lang=js&"
export * from "./Clasificacion.vue?vue&type=script&lang=js&"
import style0 from "./Clasificacion.vue?vue&type=style&index=0&id=602ee2bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "602ee2bd",
  null
  
)

export default component.exports