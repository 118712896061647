<template>
  <div class="home">
    <h1>Bienvenidos</h1>
    <div>
    <proximosPartidos />
    </div>
    <div class="row">
    <partidosJuego />
    <clasificacion v-if="partidosCargados" />
    </div>
    <premios />
  </div>
</template>

<script>
// @ is an alias to /src
import ProximosPartidos from "@/components/ProximosPartidos";
import Premios from "@/components/Premios";
import PartidosJuego from "@/components/PartidosJuego";
import Clasificacion from "@/components/Clasificacion";

export default {
  name: "home",
  data() {
    return {
      enJuego: false,
      premios: false
    };
  },
  components: {
    ProximosPartidos,
    Premios,
    PartidosJuego,
    Clasificacion
  },
  mounted() {
  },
  computed: {
     partidosCargados: function() {
       if (this.$store.state.cambioPartidos && this.$store.state.numeroPartidosEnJuego == this.$store.state.partidosEnJuego.length) {
         return true;
       }
       else {
         return false;
       }
     }
  }
};
</script>

<style>
summary {list-style:none;}
h1 {
  border:1px solid white;
  background-image: linear-gradient(to right, #000000, #dd0000, #fcee00);
  margin-top:5px;
}
</style>